<template>
  <div class="lines-bg">
    <page-sections :sections="page.fields.children" />
  </div>
</template>

<script>
import PageSections from '~/components/PageSections'

export default {
  components: {
    PageSections
  },
  asyncData: async function({ $api }) {
    return {
      page: await $api.getPage('app')
    }
  },
  head: function() {
    return {
      title: `${this.page.fields.title}`
    }
  }
}
</script>